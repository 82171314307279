<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-input v-model="query.idNum" placeholder="输入身份证" class="handle-input mr10"></el-input>
                    <el-input v-model="query.ucn" placeholder="输入统一认证ID" class="handle-input mr10"></el-input>
                    <el-input v-model="query.name" placeholder="输入姓名" class="handle-input mr10"></el-input>
                    <el-input v-model="query.keyword" placeholder="模糊检索" class="handle-input mr10"></el-input>
                    <el-select v-model="query.Type" placeholder="用户类别" style="float: left; margin-right: 10px">
                        <el-option v-for="item in query.typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                </div>
                <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
                    <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="UCN" label="统一ID" align="center"></el-table-column>
                    <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="idNum" label="身份证" align="center"></el-table-column>
                    <el-table-column prop="school" label="所在学校" align="center"></el-table-column>
                    <el-table-column prop="college" label="所在二级院校" align="center"></el-table-column>
                    <el-table-column prop="class" label="所在班级" align="center"></el-table-column>
                    <el-table-column prop="company" label="所在公司" align="center"></el-table-column>
                    <el-table-column prop="department" label="所在部门" align="center"></el-table-column>
                    <el-table-column prop="identity" label="人员类别" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" style="display: flex; justify-content: center; align-items: center">
                        <template slot-scope="scope" style="display: flex" @click="handleSelect(scope.row)">
                            <div style="display: flex">
                                <el-button
                                    type="text"
                                    style="margin-right: 10px"
                                    icon="el-icon-edit"
                                    @click="reviseUserBut(scope.$index, scope.row)"
                                    >转职</el-button
                                >
                                <!-- <el-menu mode="horizontal" style="border-bottom: none">
                                    <el-submenu index="1" style="border-bottom: none">
                                        <template slot="title">详情</template>
                                        <el-menu-item index="1" @click="handleSelect(1, scope.row)">解绑</el-menu-item>
                                        <el-menu-item index="2" @click="handleSelect(2, scope.row)">注销</el-menu-item>
                                        <el-menu-item index="3" @click="handleSelect(3, scope.row)">人脸重置</el-menu-item>
                                    </el-submenu>
                                </el-menu> -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageIndex"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-col>
        <!-- 转职修改弹出框 -->
        <el-dialog title="人员修改" :visible.sync="reviseUserstate" width="30%">
            <el-form ref="form" :model="reviseUser" label-width="150px">
                <el-form-item label="姓名" :required="true">
                    <el-input v-model="reviseUser.name" @input="inputChange($event)" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="reviseUser" label-width="150px">
                <el-form-item label="电话" :required="true">
                    <el-input v-model="reviseUser.phone" @input="inputChange($event)" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="reviseUser" label-width="150px">
                <el-form-item label="人员类别" :required="true">
                    <el-select v-model="userTypeState" placeholder="请选择人员类别" style="width: 100%" @change="userTypeListBut">
                        <el-option v-for="item in userTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="reviseUser" label-width="150px">
                <el-form-item label="所在单位" :required="true">
                    <el-select v-model="reviseUser.school" placeholder="请选择所在单位" style="width: 100%">
                        <el-option v-for="item in query.typeAddList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div v-if="userTypeState == 1">
                <el-form ref="form" :model="reviseUser" label-width="150px">
                    <el-form-item label="所在二级学院" :required="true">
                        <el-input v-model="reviseUser.college" @input="inputChange($event)"></el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="reviseUser" label-width="150px">
                    <el-form-item label="所在班级" :required="true">
                        <el-input v-model="reviseUser.grade" @input="inputChange($event)"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div v-if="userTypeState == 2">
                <el-form ref="form" :model="reviseUser" label-width="150px">
                    <el-form-item label="所在部门或二级学院" :required="true">
                        <el-input v-model="reviseUser.department" @input="inputChange($event)"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div v-if="userTypeState == 3">
                <el-form ref="form" :model="reviseUser" label-width="150px">
                    <el-form-item label="所在部门" :required="true">
                        <el-input v-model="reviseUser.department" @input="inputChange($event)"></el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="reviseUser" label-width="150px">
                    <el-form-item label="开始时间" :required="true">
                        <el-date-picker
                            v-model="reviseUser.startTime"
                            type="date"
                            @change="revisestartTimeBut"
                            placeholder="选择开始时间"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="reviseUser" label-width="150px">
                    <el-form-item label="结束时间" :required="true">
                        <el-date-picker
                            v-model="reviseUser.overTime"
                            type="date"
                            @change="reviseoverTimeBut"
                            placeholder="选择结束时间"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>

            <div v-if="userTypeState == 4">
                <el-form ref="form" :model="reviseUser" label-width="150px">
                    <el-form-item label="所在商铺号" :required="true">
                        <el-input v-model="reviseUser.department" @input="inputChange($event)"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="reviseUserstate = false">取 消</el-button>
                <el-button type="primary" @click="reviseUserOk">确定</el-button>
            </span>
        </el-dialog>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            dateValue: '2021-03-20',
            food_type: '',
            reviseUserstate: false,
            reviseUser: {},
            activeIndex: 2,
            overseas: false,
            addIdNum: '',
            addIdNumState: false,
            userTypeState: '',
            userTypeList: [
                {
                    value: '1',
                    label: '学生'
                },
                {
                    value: '2',
                    label: '员工'
                },
                {
                    value: '3',
                    label: '临时工'
                },
                {
                    value: '4',
                    label: '经营'
                }
            ]
        };
    },
    activated() {},
    created() {
        var that = this;
        var typeList = [
            {
                value: 0,
                label: '全部'
            }
        ];
        // var typeAddList = [];

        // var userType = JSON.parse(window.sessionStorage.getItem('userType'))[3];
        // for (var i = 0; i < userType.length; i++) {
        //     var userTypeObj = {
        //         value: userType[i].id,
        //         label: userType[i].name
        //     };
        //     typeList.push(userTypeObj);
        //     typeAddList.push(userTypeObj);
        // }
        var userType2 = JSON.parse(window.sessionStorage.getItem('userType'));
        for (var i = 1; i < 5; i++) {
            for (var x = 0; x < userType2[i].length; x++) {
                if (i == 1) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(学生)' + userType2[i][x].name
                    };
                } else if (i == 2) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(员工)' + userType2[i][x].name
                    };
                } else if (i == 3) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(临时工)' + userType2[i][x].name
                    };
                } else if (i == 4) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(经营)' + userType2[i][x].name
                    };
                }
                typeList.push(userTypeObj);
            }
        }
        this.query.typeList = typeList;
        console.log(this.query.typeList);
        this.getgoodList();
    },
    methods: {
        // 监听人员类别选择器
        userTypeListBut() {
            var that = this;
            var typeAddList = [];
            var userType = JSON.parse(window.sessionStorage.getItem('userType'))[that.userTypeState];
            for (var i = 0; i < userType.length; i++) {
                var userTypeObj = {
                    value: userType[i].id,
                    label: userType[i].name
                };
                typeAddList.push(userTypeObj);
            }
            this.query.typeAddList = typeAddList;
        },
        // 获取人员数据
        getgoodList() {
            var that = this;
            var query = {
                data: {
                    page: that.query.pageIndex,
                    limit: that.query.pageSize,
                    identity: that.query.Type,
                    keyword: that.query.keyword,
                    idNum: that.query.idNum,
                    UCN: that.query.ucn,
                    name: that.query.name
                    // type: 1
                },
                url: '/get_userList.cls.php'
            };
            fetchData(query).then((res) => {
                that.tableData = res.data;
                that.pageTotal = Number(res.count) || 0;
            });
        },
        // 用户搜索
        handleSearch() {
            (this.pageIndex = 1), this.getgoodList();
        },
        // 修改开始时间选择器
        revisestartTimeBut() {
            var date = this.reviseUser.startTime;
            var seperator1 = '/';
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            this.reviseUser.startTime = currentdate;
        },
        // 修改结束时间选择器
        reviseoverTimeBut() {
            var date = this.reviseUser.overTime;
            var seperator1 = '/';
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            this.reviseUser.overTime = currentdate;
        },
        // 人员修改
        reviseUserBut(index, row) {
            console.log(row);
            var that = this;
            this.reviseUser.college = row.college;
            this.reviseUser.grade = row.class;
            this.reviseUser.name = row.name;
            this.addIdNum = row.id;
            var query = {
                data: {
                    id: row.id
                },
                url: '/get_userInfo.cls.php'
            };
            fetchData(query).then((res) => {
                that.reviseUser.phone = res.data.phone;
                that.reviseUserstate = true;
            });
        },
        // 人员修改上传
        reviseUserOk() {
            var that = this;
            if (that.reviseUser.school == undefined || that.reviseUser.school == '') {
                this.$message.error('请选择所在单位');
            } else {
                console.log(that.reviseUser.school);
                if (that.userTypeState == 1) {
                    if (that.reviseUser.college == undefined || that.reviseUser.college == '') {
                        this.$message.error('二级学院不能为空');
                    } else if (that.reviseUser.grade == undefined || that.reviseUser.grade == '') {
                        this.$message.error('班级不能为空');
                    } else {
                        that.updateUserInfo();
                    }
                } else if (that.userTypeState == 2) {
                    if (that.reviseUser.department == undefined || that.reviseUser.department == '') {
                        this.$message.error('所在公司部门或二级学院不能为空');
                    } else {
                        that.updateUserInfo();
                    }
                } else if (that.userTypeState == 3) {
                    if (that.reviseUser.department == undefined || that.reviseUser.department == '') {
                        this.$message.error('所在公司部门或二级学院不能为空');
                    } else if (that.reviseUser.startTime == undefined || that.reviseUser.startTime == '') {
                        this.$message.error('请选择开始时间');
                    } else if (that.reviseUser.overTime == undefined || that.reviseUser.overTime == '') {
                        this.$message.error('请选择结束时间');
                    } else {
                        that.updateUserInfo();
                    }
                } else if (that.userTypeState == 4) {
                    if (that.reviseUser.department == undefined || that.reviseUser.department == '') {
                        this.$message.error('所在商铺号不能为空');
                    } else {
                        that.updateUserInfo();
                    }
                }
            }
        },
        updateUserInfo() {
            var that = this;
            var query = {
                data: {
                    type: that.userTypeState,
                    index: 'id',
                    id: that.addIdNum,
                    identity: that.reviseUser.school,
                    college: that.reviseUser.college,
                    class: that.reviseUser.grade,
                    department: that.reviseUser.department,
                    startTime: that.reviseUser.startTime,
                    endTime: that.reviseUser.overTime
                },
                url: '/update_userInfo.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    this.reviseUserstate = false;
                    this.$message.success(res.msg);
                    this.getgoodList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 身份证校验
        /**
         * 身份证校验算法
         */

        checktheform(card) {
            var that = this;
            //是否为空
            if (card === '') {
                return false;
            }
            //校验长度，类型
            if (that.isCardNo(card) === false) {
                return false;
            }
            //检查省份
            if (that.checkProvince(card) === false) {
                return false;
            }
            //校验生日
            if (that.checkBirthday(card) === false) {
                return false;
            }
            //检验位的检测
            if (that.checkParity(card) === false) {
                return false;
            }
            return true;
        },
        //检查号码是否符合规范，包括长度，类型
        isCardNo(card) {
            //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
            var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
            if (reg.test(card) === false) {
                return false;
            }
            return true;
        },
        //取身份证前两位,校验省份
        checkProvince(card) {
            var vcity = {
                11: '北京',
                12: '天津',
                13: '河北',
                14: '山西',
                15: '内蒙古',
                21: '辽宁',
                22: '吉林',
                23: '黑龙江',
                31: '上海',
                32: '江苏',
                33: '浙江',
                34: '安徽',
                35: '福建',
                36: '江西',
                37: '山东',
                41: '河南',
                42: '湖北',
                43: '湖南',
                44: '广东',
                45: '广西',
                46: '海南',
                50: '重庆',
                51: '四川',
                52: '贵州',
                53: '云南',
                54: '西藏',
                61: '陕西',
                62: '甘肃',
                63: '青海',
                64: '宁夏',
                65: '新疆',
                71: '台湾',
                81: '香港',
                82: '澳门',
                91: '国外'
            };
            var province = card.substr(0, 2);
            if (vcity[province] == undefined) {
                return false;
            }
            return true;
        },
        //检查生日是否正确
        checkBirthday(card) {
            var len = card.length;
            //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
            if (len == '15') {
                var re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
                var arr_data = card.match(re_fifteen);
                var year = arr_data[2];
                var month = arr_data[3];
                var day = arr_data[4];
                var birthday = new Date('19' + year + '/' + month + '/' + day);
                return this.verifyBirthday('19' + year, month, day, birthday);
            }
            //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
            if (len == '18') {
                var re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
                var arr_data = card.match(re_eighteen);
                var year = arr_data[2];
                var month = arr_data[3];
                var day = arr_data[4];
                var birthday = new Date(year + '/' + month + '/' + day);
                return this.verifyBirthday(year, month, day, birthday);
            }
            return false;
        },
        //校验日期
        verifyBirthday(year, month, day, birthday) {
            var now = new Date();
            var now_year = now.getFullYear();
            //年月日是否合理
            if (birthday.getFullYear() == year && birthday.getMonth() + 1 == month && birthday.getDate() == day) {
                //判断年份的范围（3岁到100岁之间)
                var time = now_year - year;
                if (time >= 3 && time <= 100) {
                    return true;
                }
                return false;
            }
            return false;
        },
        //校验位的检测
        checkParity(card) {
            //15位转18位
            card = this.changeFivteenToEighteen(card);
            var len = card.length;
            if (len == '18') {
                var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                var cardTemp = 0,
                    i,
                    valnum;
                for (i = 0; i < 17; i++) {
                    cardTemp += card.substr(i, 1) * arrInt[i];
                }
                valnum = arrCh[cardTemp % 11];
                if (valnum == card.substr(17, 1)) {
                    return true;
                }
                return false;
            }
            return false;
        },
        //15位转18位身份证号
        changeFivteenToEighteen(card) {
            if (card.length == '15') {
                var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                var cardTemp = 0,
                    i;
                card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
                for (i = 0; i < 17; i++) {
                    cardTemp += card.substr(i, 1) * arrInt[i];
                }
                card += arrCh[cardTemp % 11];
                return card;
            }
            return card;
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
